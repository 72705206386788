type Foo = { text: string; from: string };
export const data: Foo[] = [
  {
    text: `I could not get the RSVP to work so I am submitting here.

I regret I will be unable to attend as I will be overseas.  My love to you all.  Ingrid was a dear soul.  We did not speak often but when she did it was always with a big smile.  Andrew and my son Matthew spent some time together in middle school.  They seem to have grown apart but you both were always very welcoming to Matthew.  A loss of this magnitude is beyond words and the sudden loss makes it even more unbearable. I don&#039;t have words but if there is anything I can ever do to help in any way, please do not hesitate to call on me. Have a great celebration of what was a beautiful person who left us too soon.`,
    from: `Denise J. McCallaCreary`,
  },
  {
    text: `I worked with Paul for several years at DisplaySearch and frequently traveled on business together.   It was on one of the international trips to Berlin that I first met Ingrid.  I remember how impressed I was in my first encounter with Ingrid.  She was fun, full of life and a great conversationalist.  But what struck me, knowing Paul fairly well, was how in love he was.  He was like a little smitten puppy.  They were love birds when together (like teenagers).  It was great to see.  

During a dinner conversation with Ingrid  I briefly mentioned the fact that my wife had Celiac Disease and could not eat gluten.  She told me about how she had learned to cook for her son who had the same problem.  Then we changed topics.  A couple of weeks after my return home, we get a package in the mail from Ingrid.  She had baked a large and fantastic batch of &quot;gluten-free&quot; cookies and sent them to my wife (a complete stranger to Ingrid).  That act of kindness and graciousness had a huge impact on my wife and I.   We often think of others, but less often do we take action and actually follow through with these types of acts of kindness.  So from that time on my wife and I made a pact that when we think of others and make a comment like &quot;I think we should sent them a card&quot;, etc. that we will take action and actually follow through.  Ingrid showed what these acts of kinds can do through example.  

I also distinctly remember how proud she was of her sons.  Her family was everything to her.  

It&#039;s a tremendous loss.  If we don&#039;t make to the celebration, please know that we&#039;ll be there in spirit.`,
    from: `Rich Alires`,
  },
  {
    text: `Ingrid was, as anyone who ever met her knows, a joyful, dynamic and excellent person. Our sons, Huck and Nicky, were friends at Harker, and we also shared an alma mater, which proved an occasion for some fun sharing of memories and insights when we first met. She was always full of intelligent insights and pertinent questions, shared in a totally unpretentious, fully authentic way, and I always enjoyed talking with her at Harker events. When I took Huck to see UC Santa Cruz, it turned out that Ingrid and Nicky were there for the same tour, and we had an opportunity to glimpse into possible futures for our sons, which opened up a rich vein of discussion about education, work-life balance, the importance of charting a course independent of societal and community expectations, and the value of enjoying what one has. I believe Ingrid truly did appreciate the life she had with her wonderful family and community, and that appreciation translated into a powerful example and a host of benefits for those with whom she interacted. I feel blessed to have known her, and deeply saddened that her well-lived life has ended so prematurely. Ingrid&#039;s memory lives on in many ways, not least with the fine sons she and Paul raised. She helped make the world a better place. Namaste, Ingrid.`,
    from: `Chris Vaughan`,
  },
  {
    text: `I worked with Paul for many years, and consider him one of my most influential mentors. I had the opportunity to meet Ingrid a few times, including a work gathering at their house. I remember being amazed at the richness of their home environment and the amazing way Ingrid and Paul used that home environment to enrich their kids lives. Ingrid struck me as one of the most open, loving and inspiring mothers I have ever met. I had a chance to listen to Andy playing Piano, and could tell the love and encouragement from Ingrid was well placed. Paul and Ingrid were the &quot;power couple&quot; in the sense that together, they seemed to make all of those in their orbit better people through inspiration and example. I am grateful to have met Ingrid, even if briefly, for the impact it had on me and the way I look at my family. Best wishes to Paul, Nicky and Andy.`,
    from: `Paul Gagnon`,
  },
  {
    text: `Although we only met once or twice, through general school business, I could feel through Andrew the tremendous mother, and just wonderful human being, that Ingrid was; she is now spreading her joy elsewhere. The relationship that not only the two of you, but all four of you had, is something that is so very special. Thank you for blessing us all for 53 years. I am sorry to say that I will be out of the area on July 8th. My best to all of you&lt;3.`,
    from: `Bradley`,
  },
  {
    text: `Ingrid was a smiling presence at so many Harker events and functions, volunteering tirelessly while also enjoying the work and the interactions with others. We are so saddened by her loss and feel fortunate to have known her and worked alongside her for as long as we did.`,
    from: `Melinda Gonzales`,
  },
  {
    text: `I worked with Paul for many years, and consider him one of my most influential mentors. I had the opportunity to meet Ingrid a few times, including a work gathering at their house. I remember being amazed at the richness of their home environment and the amazing way Ingrid and Paul used that home environment to enrich their kids lives. Ingrid struck me as one of the most open, loving and inspiring mothers I have ever met. I had a chance to listen to Andy playing Piano, and could tell the love and encouragement from Ingrid was well placed. Paul and Ingrid were the &quot;power couple&quot; in the sense that together, they seemed to make all of those in their orbit better people through inspiration and example. I am grateful to have met Ingrid, even if briefly, for the impact it had on me and the way I look at my family. Best wishes to Paul, Nicky and Andy.`,
    from: `Paul Gagnon`,
  },
  {
    text: `Dancing with Ingrid at one of the last Harker Fashion shows at the San Jose Convention Center, we rocked! She loved her boys and I wish comfort and strength to you Andy!`,
    from: `Elizabet},h Saltos`,
  },
  {
    text: `I worked with Ingrid on so many Harker volunteer programs. She brought a smile to my face every time she stepped up to do that &quot;one more thing&quot;, because she was such a passionate, positive force, no matter what she was doing. I was so grateful to be able to work with her and have her support throughout the years that I worked in the Advancement team. She is sorely missed, not just because she was always willing to step up, but because she was such a caring and gracious human being.`,
    from: `Sue Prutton`,
  },
  {
    text: `Back around 1991, I met Paul when I joined an established band in Boston. He was the bass player and grudgingly served as our booking agent as well. Despite our modest skills, Paul landed us impressive gigs, usually as a warm-up act. At my first performance with the band, I was nervous and a little disappointed at the indifferent faces in the bar. Then some wild redhead dragged (literally) some people onto the dance floor and began her own spastic gyrations. Needless to say, it was Ingrid, and I quickly learned that she was our most loyal fan. At every gig, she hooted the loudest and danced with a kind of abandon demonstrated her free-spirit nature.
Paul and Ingrid became good friends to me and my wife. That was when I learned that Ingrid was no vacuous groupie. Her facility with language came across in conversation, but became more apparent when I learned that she could hold those conversations in several languages. She remains the only person I ever met who could read Anna Karenina in a weekend.  She complemented these academic skills with practical skills like baking, showing her more down-to-earth side.  She brought a blueberry pie to small party once, and my eyes actually welled with tears after my first forkful.
We fell out of touch when Paul and Ingrid headed to California, but as is so often the case, reunited (partially) on social media. There I discovered a different side of her, as a mom who still fiercely followed her own path. We&#039;ll miss her, and we wish Paul, Nicky, and Andy strength.`,
    from: `Bob Black`,
  },
  {
    text: `I am deeply saddened to hear of Ingrid&#039;s passing. I was honored to have her son, Nick, in my advisory and in that way had a chance to cross paths with Paul and Ingrid over the four years of advisory. My lasting memory of Ingrid is one of complete devotion to her family along with her own amazing personal style and grace. My sincerest wishes for peace for all who&#039;s lives she touched, as she will be missed immensely. (And a special hug to Nick from his advisor...)`,
    from: `Lori Kohan`,
  },
  {
    text: `Ingrid just lit up every event she attended here at Harker, and I always loved chatting with her. In reading her bio, I&#039;m also amazed at how much I didn&#039;t know about this incredible woman. She was clearly a force. Most importantly, she left a mark on the hearts of so many. Andy, Nicholas and Andrew - what a gift your wife and mother was to us all. Huge hugs to you.`,
    from: `Pam Dickinson`,
  },
  {
    text: `I had only known Ingrid a short time while we worked together at Virtual Instruments but it was obvious from the start that she was a special person and full of verve.  The photos you have assembled on this website are certainly a re-confirmation of that!  Wishing the entire Semenza family peace and comfort.`,
    from: `Dan},a DeForge-Skrabak`,
  },
  {
    text: `Once I got past the initial shock and deep sadness when I learned of Ingrid&#039;s death, I was flooded with memories from the time we shared while living in Boston. Ingrid will always been one of the most interesting people I have known. She was as passionate about General Hospital as she was about Russian Literature. And she was fearless in the kitchen - a true foodie, way ahead of her time. She made Bob&#039;s and my wedding cake (carrot with cream cheese frosting). Much to our delight, she needed us to taste different versions before she had adjusted ingredients to her liking (it all tasted delicious to Bob and me!).  I admired the close bond she had with her sister Wendy. And when she became a mother, Ingrid was so clear about her path. I was in awe of that. In recent years, I relished the glimpses of her life that I caught on Facebook- her vitality and fierce devotion to Paul and her sons came shining through. I miss you Ingrid! Love to Paul, Nicky and Andrew.`,
    from: `Melissa Walters`,
  },
  {
    text: `Ingrid was a member of our UJAM family. You could always count on her to be a few minutes late (piano lessons were always before class), turn on the fan, and be a presence in the front row spinning away to the music. Her energy was contagious, and she had her music preferences - the louder the better  (except if it was Bollywood or Zumba - then forget it)!  She will never be forgotten by her YMCA crew, and I still blow a kiss to her spirit every time I turn on the fan. Sending much love to Paul, Nicky, and Andy.`,
    from: `Jeni Goedken`,
  },
  {
    text: `I have taken Ujam for the past 6 years and I could always count on Ingrid to be in class. She always came running into class, taking her spot in the front of class. She had so much energy. It was impressive. Every now and then we would talk about life outside of class. Often she spoke of her boys. I was at a loss when I heard of her passing as I was just in class with her the night before. Our next class was filled with tears as her absence was felt in class. Someone remarked that night, &quot;I saw Ingrid l more than I saw members of my own family&quot;. This statement really drove home how important Ingrid was to our Ujam family and spoke to the impact she had on our lives. I rarely go to Ujam on Sundays, the next time I tried to go, I was worried I wouldn&#039;t know anyone. As I walked to class, I thought about how Ingrid would be there and I would not be alone. I started to cry as I realized she would not be there. Ingrid will be greatly missed. She touched so many lives in our Ujam community. I feel lucky to have known her and to have danced with her each week for the past 6 years.`,
    from: `Melissa Diaz`,
  },
  {
    text: `I&#039;m so sorry not to be at the memorial for Ingrid. Ingrid brought a freshness to those around her. Her energy was contagious - she always smiled and remembered what you were up to and what you were planning to do. Ingrid was devoted to her boys - she took such pride in their accomplishments. I&#039;m devastated that she didn&#039;t get to hear Andy play at Carnegie Hall. I loved Ingrid partly because she led her life the way she wanted to - not worrying about what others were doing, dancing to her own beat, whether it was at UJam or the classical music she loved. I appreciated her baking skills - always fun when she had extras for us at work. I miss being able to call her up and ask questions about a recipe - we used to do that quite often. I miss her shoes! she was crazy about about them and most of us could never understand how she could walk in those heels. I just miss her...`,
    from: `Ruth Mohanram`,
  },
  {
    text: `I was a classmate and fellow bandmate of Ingrid&#039;s at Leominster High School. She was brilliant. In my opinion, the brightest light shone among the 500+ in our graduating class. I always knew she&#039;d live a rich life, full of examples for us to follow or emulate. Every day at school, she had a smile. Life didn&#039;t challenge Ingrid, she challenged life.`,
    from: `Chris Valeri`,
  },
  {
    text: `The thing I&#039;ll remember most about Ingrid was her eagerness to help.  Whenever Ingrid detected even a slight hint of someone needing help,  she would leap into action to provide helpful suggestions or offer
assistance in any and all ways possible.  Most people would not even bother or would be too  shy to offer up help, but not Ingrid.  Such
determination and creativity! I miss her dearly.`,
    from: `Rosanna Lee`,
  },
  {
    text: `During the many years I knew Ingrid at Harker she was a devoted parent and a very committed volunteer. There she was at so many events, always with a smile, and always willing to help out with advice, ideas, and in action. The parent community, the students, and the school benefited immensely from everything she did. I will miss her as a friend and wish her family my deepest condolences.`,
    from: `Naren Nayak`,
  },
  {
    text: `About ingrid and me

Ingrid Semenza became, aside from my husband, the best friend I have ever had in my life.

I remember meeting Ingrid as a member of a small mommy and baby group, a La Leche League group spinoff in Jenny Strand park.  She had her little Nicky and I had my little Vishal. As we talked, she casually mentioned that she walked to and from the park for this play group, pushing Nicky’s stroller.  The walk was three and a half miles round trip, which, along with the exhaustion of caring for a six-month-old, seemed daunting to me.   I looked at her and said, half-jokingly, “You’re my hero!”

As we became close friends, Ingrid continued to be my hero.  Over the years we shared the enjoyment and challenges of almost fierce attachment parenting, rigorous home schooling, the happy arrivals of our second children, Andy and Shreeya, then transitioning our children to conventional schooling.   She always had something nice to say, along with a suggestion as to how to make things better.  She always provided affection and support to me and to our whole family. She was a rock to us.

We managed to keep each other amused.  I adored her razor sharp, droll wit. She was never hesitant to succinctly judge anyone’s actions or any situation. She always made me laugh.  We laughed about everything, happy, sad, important, or mundane and insignificant.  

Our families became close.  Ingrid and I touched base almost every week-day on the phone.  Frequently, on weekends, our families would get together, for dinner, or for dessert, or just for a quick late night cocktail.  

She made me a better person.  She helped my family be a better family.   Recently, before she died, our responsibilities toward our children had started to diminish.  In the back of my mind, I had ideas for us in the coming years.  We had talked about “both kids in college”, and the possibilities opening up for us, as though WE were the ones going off to college.  We discussed leisure, new jobs, volunteer work, study.

Then Ingrid died suddenly.  This was not our plan.

To Andy, to Nicky, to Paul, I offer my deep sympathy on the loss of your mother and wife.   To Wendy, to everyone else who loved and was loved by Ingrid, I offer sympathy also.  My hero.  My friend.`,
    from: `Alicia Woodrow`,
  },
  {
    text: `Alicia and Ingrid met at the La Leche League meeting when our son Vishal was about 2 months old. The Semenzas have been a part of our life since then. I found myself describing Ingrid to someone as the second mother of our kids, and she was.

Ingrid was very smart. She had a restless intellect and an alive and wide ranging curiosity. Conversations with her ranged from high brow to low brow. She was equally comfortable with Russian literature, physics and calculus problems, literary and science fiction, fashion, interior design, current trends among celebrities, and TV shows trashy and sublime. I am still reading the last book that she enthusiastically recommended to me, a science fiction novel, &quot;Seveneves&quot;, by Neal Stephenson.

We all had countless meals together. She loved Indian food and could eat more spicy food than I can. I think only Vishal and Andy could keep up with her.

She was an innovative and accomplished baker. She started baking gluten free goodies because Nicky and Andy were allergic to gluten for a few years, and Vishal and I were the collateral beneficiaries. Her gluten free cakes and cookies were better than any I have had in any bakery. Alicia and I often asked her to start her own baking business.

Ingrid also had an impeccable aesthetic sense. Ingrid and Paul’s house is a testimony to that. She acted as a de facto consultant, and sometimes a tie-breaker, for clothes, furniture and remodelling choices for our family.

She was dependable and she was always there for us, but what I miss the most is her warmth and her enthusiasm for life. I am sure I will go on fewer hikes now that she is gone. Alicia and I just came back from 7 day driving trip to Oregon and not a day passed where something did not remind us of her. 

We were lucky to have her in our life for as long as we did. We miss her and we will miss her.`,
    from: `Jiten`,
  },
  {
    text: `I had the pleasure of meeting Ingrid through Virtual Instruments where we worked.  Ingrid was incredibly thoughtful and compassionate, and sincerely cared about the well-being of others.  In addition to being one of the most intelligent and interesting people, I have met in my life.

Ingrid, may you be at peace.  Please know your presences made the world a better place.  I am blessed and honored to have met you.  

Sincerely,
Kathleen Nichols`,
    from: `Kathleen Nichols`,
  },
  {
    text: `Although we only had a couple of short encounters with Ingrid, her warm smile and cheerfully spirit is forever memorable. We became the Semenza’s neighbors just a few months ago. On one January evening, we were making our rounds to introduce ourselves to our immediate neighbors. When we knocked Paul and Ingrid’s door, Ingrid greeted us with a wide, bright-eyed smile and welcomed us into their home. She was cheerful, friendly and warm. Ingrid was also a fantastic listener… attentively listening to our story and our stressful (yet exciting!) pursuit of our very first home in Silicon Valley. She even showed us around her home and told us about the renovations Paul and her had done some years ago; and Paul showed us his outdoor office. Wow! Were we ever impressed…but more than anything, we felt humbled and grateful for having such delightful neighbors. Andy was also there. Ingrid made a point of telling us that he “loves to play his piano”, and asked if we would be bothered by that. Needless to say, we had heard Andy play, and knew he was an incredibly talented pianist. We told Ingrid not to worry, as we were already mesmerized by Andy’s music. She smiled. 

We’re sorry we didn’t have the opportunity to get to know Ingrid more, but we are comforted by the fact she is survived by an incredible family we get to call our neighbors. Her memory will forever live on. And as for Andy, please keep doing what you do best – play incredible music that makes our hearts sing. 

Sincerely,
Jad &amp; Ihssan`,
    from: `Jad &amp; Ihssan Faraj`,
  },
  {
    text: `I worked with Ingrid at Xangati and she would come back each day with that same boundless energy and enthusiasm. She always had a smile and something to contribute to any discussion. She is inspired me in more ways than I had thought! Be it staying fit, or managing work/life, or shopping, or choosing chocolates as gifts! It was amazing to see what a dedicated mom she was, and she did such an awesome job of raising her boys. Now thinking about her, I will always remember her as a very genuine person who knew how to live life!`,
    from: `Swati Gupta`,
  },
];

import React from "react";
import { data } from "./data";

function App() {
  return (
    <div className="container mx-auto max-w-4xl">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Ingrid Helene Semenza
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            November 8, 1963 - March 2, 2017
          </p>
        </div>
      </div>

      <div>
        <img
          className="float-left mr-4 my-2"
          style={{ maxWidth: "300px" }}
          src="https://photos.smugmug.com/photos/i-KgXZmvp/0/26f73388/X3/i-KgXZmvp-X3.jpg"
        />
        Ingrid Helene Semenza, loving and adored mother, wife, sister, and
        friend, died on March 2, 2017, at the age of 53, in San Jose,
        California. Ingrid was born in Whalley, UK, and moved with her family to
        the US in 1970, and grew up in Leominster, Massachusetts. She attended
        public schools in Leominster, where she distinguished herself
        academically, in cross-country running, and as a competitive chess
        player.
        <br />
        <br />
        Ingrid was a voracious reader, had a lifelong love of literature and
        languages, and was able to read in Russian, French, German, and Latin.
        She was awarded a B.A. in English and Russian Language and Literature,
        cum laude, from Tufts University in 1985. Ingrid then studied at Brown
        University, where she was awarded an A.M. in 1990 and a Ph.D. in 1996,
        both in Slavic Languages. Her dissertation was entitled “Faith Amidst
        the Chaos: Towards an Understanding of the Religious Message Embedded in
        Dostoyevsky’s Besy” (the title of this novel is variously translated as
        The Possessed and The Devils).
        <br />
        <br />
        From a young age, Ingrid was responsible for her living expenses and
        education, and worked her way through high school (at a movie theater
        and amusement park), college (working her way up from the dishroom to
        Student Manager of Tufts Dining Services), and graduate school (serving
        as a teaching assistant for undergraduate Russian courses and a teacher
        for English as a Foreign Language at Brown). She supported herself with
        many other jobs along the way, tending bar, waiting tables, and in
        retail, where she was a top salesperson in high-end kitchen supply shops
        and clothing stores in Boston.
        <br />
        <br />
        Ingrid married Paul Semenza in 1994, and they moved to Maryland, where
        they lived for three years. Ingrid worked on her dissertation, which she
        delivered in October 1995, while working at the American College of
        Cardiology.
        <br />
        <br />
        In August of 1996, Ingrid gave birth to her first son, Nicholas, which,
        along with the birth of her second son, Andrew, four years later,
        transformed her life. Ingrid dedicated the next decade of her life to
        attachment parenting and home schooling of her sons. She was active in
        La Leche League, supporting many nursing mothers, and various
        home-schooling groups in Santa Clara, California, where she moved with
        Paul and Nicky in 1997. Ingrid provided a rigorous education for her
        sons, who entered The Harker School in San Jose in 2007 and 2008. She
        taught independently from 2009 to 2012, teaching individual students
        Math, Latin, and SAT preparation.
        <br />
        <br />
        Ingrid loved to bake, always taking the opportunity to bake something to
        bring to parties or give as gifts. This included the monumental task of
        baking her own wedding cake, a carrot cake with white chocolate cream
        cheese frosting, which she baked, froze, transported from Maryland to
        New York and then assembled and frosted. Having discovered that her sons
        were allergic to not only gluten but also dairy, Ingrid turned her
        talents toward creating all her favorite baked treats without wheat
        flour, butter, or milk. She has passed this love on to her sons, and
        Nicky has been baking for his fellow students at Purdue.
        <br />
        <br />
        As her sons transitioned into Harker, Ingrid became an active volunteer
        and fundraiser, serving as a grade level coordinator, and chairing
        auctions and other functions for the Fashion show, planning the family
        picnic, and participating in the Parent Development Council supporting
        Annual Giving campaigns.
        <br />
        <br />
        Ingrid was always active, and she loved walking and dancing. She always
        sought out the opportunity to join dance classes wherever she moved,
        from Jazzercise to Zumba to UJAM. She was known as an enthusiastic
        participant in classes at the YMCA in the San Jose area. She always
        preferred to walk to shop or run errands, and recently had been walking
        6 miles a day to and from her job.
        <br />
        <br />
        Ingrid also was a strong advocate of music education and performance,
        supporting piano education for both her sons. Andy has been playing
        classical piano for over a decade, and has participated in international
        piano competitions and festivals.
        <br />
        <br />
        In 2014, Ingrid started a new phase, taking a position at Xangati, a
        technology company in San Jose as an office manager, where she
        epitomized the concept of jack-of-all trades, rapidly taking on support
        roles in finance, HR, marketing, sales, and executive support. One of
        her favorite responsibilities was employee happiness, in which she
        worked as a problem-solver, event planner, and all-around team player.
        Ingrid was completing the transition of the business to Virtual
        Instruments, which acquired Xangati in 2016.
        <br />
        <br />
        Ingrid is survived by her husband Paul, sons Nicholas and Andrew, sister
        Wendy Nilsson and brother Torbjorn Phillpotts.
      </div>

      <hr />

      <iframe
        src="http://www.nicky.photos/frame/slideshow?key=MSn7RF&autoStart=1&captions=1&navigation=1&playButton=0&randomize=1&speed=3&transition=fade&transitionSpeed=1"
        width="100%"
        height="800"
        frameBorder="no"
        scrolling="no"
      ></iframe>

      <hr />
      <div className="">
        <h4 className="text-lg leading-6 font-medium text-gray-900">
          Please join us to share memories of Ingrid and to celebrate her life
          and the impact she has had on you and others.
        </h4>
        <p className="mt-2 text-base leading-6 text-gray-500">
          July 8, 2017, 1pm <br />
          Semenza Home
        </p>
      </div>

      <div className="flex flex-column flex-wrap">
        {data.map((d) => (
          <div className="max-w-sm rounded overflow-hidden shadow-lg p-2">
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{d.from}</div>
              <p className="text-gray-700 text-base">{d.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
